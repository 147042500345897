"use client";
import { useMemo } from "react";
import useSWR from "swr";

import { fetcher } from "@aprosoja/api";
import { GetCategoriesData, GetMediasData, GetNewsData } from "@aprosoja/types";

const useHome = () => {
  const { data: categoriesData } = useSWR<GetCategoriesData>(
    [`/categories?status=true&highlight=true`],
    async ([url]) => fetcher(url),
    { revalidateOnMount: true, revalidateOnFocus: false }
  );

  const { data: featuredNews } = useSWR<GetNewsData>(
    [`/news?status=true&highlight=true`],
    async ([url]) => fetcher(url),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  );

  const { data: mediaData, isLoading } = useSWR<GetMediasData>(
    [`/medias`],
    async ([url]) => fetcher(url),
    { revalidateOnMount: true, revalidateOnFocus: false }
  );

  const orderedFeaturedNews = useMemo(
    () =>
      featuredNews?.data.sort((a, b) => {
        return (
          new Date(a.createdAt as string).getTime() -
          new Date(b.createdAt as string).getTime()
        );
      }) ?? [],
    [featuredNews]
  );
  const firstTwoRecentFeaturedNews = orderedFeaturedNews.slice(0, 2);
  const remainingRecentFeaturedNews = orderedFeaturedNews.slice(2, 6);

  const media = mediaData?.data;

  const photos = useMemo(
    () =>
      media
        ?.filter((it) => it.type === "PHOTO")
        ?.flatMap((media) =>
          media.files!.map((file) => ({
            createdAt: media.createdAt ?? "",
            file,
            title: media.title,
          }))
        ),
    [media]
  );

  const categories = useMemo(
    () => categoriesData?.data ?? [],
    [categoriesData]
  );

  return {
    photos,
    categories,
    firstTwoRecentFeaturedNews,
    remainingRecentFeaturedNews,
  };
};

export default useHome;
