import { Children } from "react";
import Link from "next/link";

import { CardNews } from "@aprosoja/containers";
import { useMediaQuery } from "@aprosoja/hooks";
import {
  BREAKPOINT_DESKTOP_MEDIUM,
  BREAKPOINT_TABLET_SMALL,
} from "@aprosoja/styles/breakpoints";
import { colors } from "@aprosoja/styles/tokens";
import { CategoryType } from "@aprosoja/types";
import { Button, Skeleton, Text } from "@aprosoja/ui";

type CardNewsByCategoryProps = {
  data?: CategoryType;
};

const CardNewsByCategory = ({ data }: CardNewsByCategoryProps) => {
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINT_TABLET_SMALL})`);
  const isTablet = useMediaQuery(`(max-width: ${BREAKPOINT_DESKTOP_MEDIUM})`);

  const activeNews = data?.news.filter((item) => item.status === true);

  return (
    <div className="flex flex-col gap-size-x6 w-full">
      <div className="flex justify-between gap-size-x4">
        {data ? (
          <>
            <Text type="headingS" color={data.color as keyof typeof colors}>
              {data?.name}
            </Text>
            <Link href={`/categoria/${data.slug}`} passHref>
              <Button variant="text" className="whitespace-nowrap">
                Ver mais
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Skeleton className="w-[156px] h-[36px] bg-neutral-300" />
            <Skeleton className="w-[82px] h-[36px] bg-neutral-300" />
          </>
        )}
      </div>
      {activeNews
        ? Children.toArray(
            activeNews
              .slice(0, 3)
              .map((item, index) => (
                <CardNews
                  key={item.id}
                  data={item}
                  orientation={
                    (isMobile && "vertical") ||
                    (isTablet && "horizontal") ||
                    "vertical"
                  }
                  hasImage={index === 0}
                />
              ))
          )
        : [0, 1, 2, 3].map((index) => (
            <CardNews key={index} hasImage={index === 0} />
          ))}
    </div>
  );
};

export default CardNewsByCategory;
